import { mq } from "../../assets/styles/mediaqueries";

export const titleStyle = {
    textAlign: 'center',
    marginBottom: '25px',
}
export const subtitleStyle = {
    textAlign: 'center'
}

export const homeStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}