/** @jsxImportSource @emotion/react */
import { titleStyle, subtitleStyle, homeStyle } from './styles'

export default function HomeLayout({ siteTitle }) {
    return (
        <div css={homeStyle}>
            <h1 css={titleStyle}>{siteTitle}</h1>
            <p css={subtitleStyle}>MODEL - ACTOR - FACE - ATYPICAL PROFIL</p>
        </div>
    )
}